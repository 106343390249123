import { blackColor } from '@flash-tecnologia/flamingo-ui/src/baseStyles'
import styled from 'styled-components'

export const Banner = styled.div`
  height: ${(props) => props.height ? props.height : '56px'};
  background: ${(props) => props.background ? props.background : '#FEA034'};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 10px;
  gap: 16px;
  width: ${(props) => props.width ? props.width : '100%'};
`

export const CustomMainText = styled.p`
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.mainTextColor ? props.mainTextColor : blackColor};
  flex-grow: 0;
`

export const BannerLink = styled.a`
  text-align: center;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  text-transform: uppercase;
  cursor: pointer;
  color: ${(props) => props.linkTextColor ? props.linkTextColor : blackColor};
  :hover {
    text-decoration: underline;
    color: ${(props) => props.linkTextColor ? props.linkTextColor : blackColor};
  }

`
