import { AddIcon, P } from '@flash-tecnologia/flamingo-ui'
import { pinkColor } from '@flash-tecnologia/flamingo-ui/src/baseStyles'
import { cnpjMask } from '@flash-tecnologia/flamingo-ui/src/utils'
import { createBrowserHistory } from 'history'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { logout } from '../../containers/LogInAndOut/actions'
import { identifyUserAnalytics } from '../../containers/LogInAndOut/saga'
import { getFromLS, setInLS } from '../../services/security'
import Dropdown, { DropdownMenu, MenuItem } from '../uielements/dropdown'
import './scroll.css'

const HROS = process.env.HROS

const browserHistory = createBrowserHistory({ forceRefresh: true, ...(Number(HROS) && { basename:   '/benefits' }) })

const Companies = props => {
  const { localStyles, changeCompany, onItemHover } = props

  const { companies = [], companyId: actualCompanyId } = getFromLS('clientData')

  const newCompaniesArray = companies.filter(
    company => company._id !== actualCompanyId,
  )

  return (
    <div>
      <div style={localStyles.accountsHeader}>
        <span style={localStyles.myCompanyLabel}>Minhas empresas</span>
        <span
          onClick={() =>  browserHistory.push('/dashboard/company/central')}
          style={localStyles.viewAllLabel}
        >
          ver todas
        </span>
      </div>
      <div className='companiesList' style={localStyles.companiesListDiv}>
        {newCompaniesArray.map((company, index) => {
          const { companyName, cnpj, _id: companyId } = company

          let initials = companyName.match(/\b\w/g) || []
          initials = (
            (initials.shift() || '') + (initials.shift() || '')
          ).toUpperCase()
          return (
            <MenuItem
              {...{ onItemHover }}
              className='ant-dropdown-menu-item'
              key={index}
              style={localStyles.companiesListMenuItem}
              onClick={() => changeCompany(companyId)}
            >
              <div
                style={{
                  ...localStyles.companiesListInitialsDiv,
                  ...localStyles.circleSubItemsMenu,
                }}
              >
                <span style={localStyles.companyInitialText}>{initials}</span>
              </div>
              <div
                style={{
                  ...localStyles.dropdownMenuItem(false, false),
                  ...localStyles.companiesListDataDiv,
                }}
              >
                <p style={localStyles.companyName}>
                  {companyName}
                </p>
                <p style={localStyles.companyCNPJ}>CNPJ: {cnpjMask(cnpj)}</p>
              </div>
            </MenuItem>
          )
        })}
      </div>
    </div>
  )
}

const UserDiv = styled.div`
  box-shadow: 0px 1px 2px 0px rgba(255, 110, 96, 0.5);
  &:hover {
    box-shadow: 0px 2px 7px 0px rgba(255, 110, 96, 0.7);
  }
`
const IconSpanAddCompany = styled.span`
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  border-radius: 100%;
  align-items: center;
  margin-right: 10px;
`

const AddCompanyDiv = styled.div`
  font-weight: bold;
  width: 100%;
  color: #fff;
  cursor: pointer;
  height: 50px;
  display: flex;
  padding: 0px 20px;
  align-items: center;
  border-bottom: 1px solid rgb(255, 255, 255, 0.3);

  &:hover ${IconSpanAddCompany} {
    background-color: rgba(255, 255, 255, 0.3);
  }
`

class TopbarUser extends Component {
  companyInfos = this.findCurrentCompany()

  constructor(props) {
    super(props)

    this.handleVisibleChange = this.handleVisibleChange.bind(this)
    this.hide = this.hide.bind(this)
    this.state = { visible: false }

    this.localStyles = {
      addCompanyContainer: {
        fontWeight: 'bold',
        width: '100%',
        color: '#fff',
        cursor: 'pointer',
        height: '50px',
        display: 'flex',
        padding: '0px 20px',
        alignItems: 'center',
        borderBottom: '1px solid rgb(255,255,255,0.3)',
      },
      addCompany: {
        fontWeight: 'bold',
        fontSize: '16px',
      },
      companiesListDiv: {
        display: 'flex',
        flex: 1,
        maxHeight: '200px',
        overflowY: 'scroll',
        flexDirection: 'column',
      },
      companiesListDataDiv: {
        cursor: 'pointer',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
      },
      companiesListInitialsDiv: {
        display: 'flex',
        width: '38px',
        height: '38px',
        fontWeight: '700',
        fontSize: '18px',
        borderRadius: '100%',
        background: 'rgb(255, 255, 255)',
        marginRight: '10px',
      },
      companiesListMenuItem: {
        display: 'flex',
        width: 'inherit',
        cursor: 'pointer',
        margin: '5px 0',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '0 20px',
        height: '50px',
      },
      companyInitialText: { color: pinkColor, margin: 'auto' },
      companyName: { display: 'flex', flexWrap: 'wrap', margin: 0 },
      companyCNPJ: {
        lineHeight: '20px',
        fontSize: '13px',
        fontWeight: '400',
      },
      dropdownMenu: { width: 'auto' },
      dropdownMenuItem: (showBorder, withPadding = true) => ({
        width: '100%',
        cursor: 'pointer',
        padding: withPadding ? '10px 20px' : 'unset',
        ...(showBorder && {
          borderBottom: '1px solid rgb(255,255,255,0.3)',
        }),
      }),
      dropDownHeaderItem: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        height: '60px',
        padding: '0px 20px',
        borderBottom: '1px solid rgb(255,255,255,0.3)',
        color: 'rgb(255, 255, 255)',
      },
      headerItemCompany: {
        fontWeight: 'bold',
        fontSize: '16px',
      },
      headerItemCnpj: {
        fontSize: '14px',
        cursor: 'inherit',
      },
      dropdownMenuItemContent: {
        color: '#fff',
        cursor: 'pointer',
      },
      helpIcon: {
        fontSize: '30px',
        color: pinkColor,
        marginTop: '1px',
        marginRight: '10px',
      },
      menuItem: { width: 'inherit' },
      settingsDiv: {
        display: 'flex',
        flex: 1,
        marginRight: '10px',
        alignItems: 'center',
        justifyContent: 'center',
      },
      settingsIcon: { width: '30px' },
      userDropdownMenu: { width: 'auto', minWidth: '250px' },
      accountsHeader: {
        display: 'flex',
        padding: '5px 10px 10px',
        width: '100%',
        justifyContent: 'space-between',
        fontSize: '16px',
        alignItems: 'baseline',
        color: 'rgb(255, 255, 255)',
      },
      myCompanyLabel: {
        fontWeight: 'bold',
      },
      viewAllLabel: {
        textTransform: 'uppercase',
        fontSize: '10px',
        cursor: 'pointer',
        fontWeight: 'bold',
      },
      circleSubItemsMenu: {
        width: '48px',
      },
    }
  }

  hide() {
    this.setState({ visible: false })
  }

  handleVisibleChange() {
    this.setState({ visible: !this.state.visible })
  }

  changeCompany = companyId => {
    const clientData = getFromLS('clientData')
    const {
      companySteps,
      employeesQuantity,
      featureConfig,
      groupId,
      haveExpenseManagement,
      havePlasticActiveBenefit,
      haveVirtualActiveBenefit,
      hasContracts,
      invoice,
      isFirstOrder,
      path,
      permissions,
      reports,
    } = clientData.companies.find((company) => (company._id === companyId))
    setInLS('clientData', {
      ...clientData,
      companyId,
      companySteps,
      employeesQuantity,
      featureConfig,
      groupId,
      haveExpenseManagement,
      havePlasticActiveBenefit,
      haveVirtualActiveBenefit,
      hasContracts,
      invoice,
      isFirstOrder,
      path,
      permissions,
      reports,
    })
    identifyUserAnalytics()
    this.goToPage('/dashboard/company/')
    window.location.reload()
  }

  goToPage = (path = '/') => {
    browserHistory.push(path)
  }

  companyDropdown = () => {
    // TODO: removes
    const clientData = getFromLS('clientData')

    return (
      <DropdownMenu style={this.localStyles.userDropdownMenu}>
        {this.headerDropdownFloatMenu()}
        <Companies
          localStyles={this.localStyles}
          changeCompany={this.changeCompany}
        />

        <AddCompanyDiv
          onClick={() => this.goToPage('/dashboard/company/register')}
        >
          <IconSpanAddCompany>
            <AddIcon />
          </IconSpanAddCompany>
          <span style={this.localStyles.addCompany}>Cadastrar empresa</span>
        </AddCompanyDiv>

        <MenuItem
          key='1'
          style={this.localStyles.dropdownMenuItem()}
          onClick={() => this.goToPage('/dashboard/adminProfile')}
        >
          <P style={this.localStyles.dropdownMenuItemContent}>Perfil</P>
        </MenuItem>
        <MenuItem
          key='2'
          style={this.localStyles.dropdownMenuItem()}
          onClick={() => this.goToPage('/dashboard/corporateProfile')}
        >
          <P style={this.localStyles.dropdownMenuItemContent}>
            Dados da empresa
          </P>
        </MenuItem>
        <MenuItem
          key='3'
          style={this.localStyles.dropdownMenuItem()}
          onClick={() => this.goToPage('/dashboard/users')}
        >
          <P style={this.localStyles.dropdownMenuItemContent}>
            Gerenciar administradores
          </P>
        </MenuItem>
        {clientData.featureConfig?.apiEnabled && (
          <MenuItem
            key='5'
            style={this.localStyles.dropdownMenuItem(true)}
            onClick={() => this.goToPage('/dashboard/company/api')}
          >
            <P style={this.localStyles.dropdownMenuItemContent}>
              Acesso programático
            </P>
          </MenuItem>
        )}
        <MenuItem
          onClick={this.props.logout}
          key='logout-menu-item'
          style={this.localStyles.dropdownMenuItem()}
        >
          <div>
            <p>Sair</p>
          </div>
        </MenuItem>
      </DropdownMenu>
    )
  }

  findCurrentCompany() {
    const clientData = getFromLS('clientData')

    const currentCompany = clientData.companies.find(
      company => company._id === clientData.companyId,
    )
    let initials = currentCompany.companyName.match(/\b\w/g) || []
    initials = (
      (initials.shift() || '') + (initials.shift() || '')
    ).toUpperCase()
    return { ...currentCompany, initials }
  }

  headerDropdownFloatMenu() {
    return (
      <div style={this.localStyles.dropDownHeaderItem}>
        <div style={this.localStyles.companiesListInitialsDiv}>
          <span style={this.localStyles.companyInitialText}>
            {this.companyInfos.initials}
          </span>
        </div>
        <div>
          <p style={this.localStyles.headerItemCompany}>
            {this.companyInfos.companyName}
          </p>
          <p style={this.localStyles.headerItemCnpj}>
            CNPJ: {cnpjMask(this.companyInfos.cnpj)}
          </p>
        </div>
      </div>
    )
  }

  openOnboarding = () => {
    setInLS('clientData', {
      ...getFromLS('clientData'),
      finishedOnboarding: false,
      onboardingClosable: true,
      onboardingIndex: 0,
    })
    window.location.reload()
  }

  render() {
    return (
      <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
        <div style={{ display: 'flex', flex: 1 }}>
          <Dropdown
            overlay={this.companyDropdown}
            style={{ top: '6px' }}
            placement='bottomLeft'
            trigger={['click']}
          >
            <UserDiv
              style={{
                ...this.localStyles.companiesListInitialsDiv,
                ...this.localStyles.floatMenuInitials,
              }}
            >
              <span style={this.localStyles.companyInitialText}>
                {this.companyInfos.initials}
              </span>
            </UserDiv>
          </Dropdown>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({ reducer: state.logInAndOut })

export default connect(mapStateToProps, { logout })(TopbarUser)
