import { ConnectedRouter } from 'connected-react-router'
import {
  errorBackMessages,
} from '@flash-tecnologia/flamingo-ui/src/utils'
import { getAccessTokenPayloadSync, useSelectedCompany } from '@flash-tecnologia/hros-web-utility'
import moment from 'moment-timezone'
import React from 'react'
import { connect } from 'react-redux'
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'
import feedbackMessage from './components/feedback/message'
import App from './containers/App/App'
import asyncComponent from './helpers/AsyncFunc'
import { getFromLS, setInLS } from './services/security'
import { useUnleashContext } from '@unleash/proxy-client-react'
import * as Sentry from '@sentry/react'

const URL = process.env.URL
const HROS = process.env.HROS
const MAINTENANCE_PAGE = process.env.MAINTENANCE_PAGE
const NODE_ENV = process.env.NODE_ENV
const SIGNUP_URL = URL === 'production'
  ? 'https://hros.flashapp.com.br/authentication/first-access?variant=company&redirectTo=%2Fsignup%2Fself%2Fcreate-company&source=redirect-from-legacy-signup'
  : 'https://staging-platform-hros-web-root.flashapp.site/authentication/first-access?variant=company&redirectTo=%2Fsignup%2Fself%2Fcreate-company&source=redirect-from-legacy-signup'

const SentryRoute = Sentry.withSentryRouting(Route)

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const {
    adminId,
    companyId,
    emailAssociated,
    env,
    groupId,
    path,
    permissions,
    timestamp,
  } = getFromLS('clientData') || {}

  let company = {}
  if (Number(HROS)) {
    company = useSelectedCompany()
  }
  
  const updateContext = useUnleashContext()
  updateContext({ userId: adminId, companyNanoId: company?.selectedCompany?.id, properties: { economicGroupId: groupId, companyId }})
  
  let hasPermission = true
  updateContext({ userId: adminId, properties: { economicGroupId: groupId, companyId }})
  if (NODE_ENV === 'production') Sentry.setUser({ username: adminId, email: emailAssociated })
  switch (rest?.location?.pathname) {
    case '/dashboard/company/reports':
      hasPermission = permissions?.reports?.pageView
      if (!hasPermission) {
        hasPermission = false
        feedbackMessage(errorBackMessages['reports.pageView'])
      }
      break
    case '/dashboard/company/payments':
      hasPermission = permissions?.billets?.pageView
      if (!hasPermission) feedbackMessage(errorBackMessages['billets.pageView'])
      break
    case '/dashboard/company/deposits':
      hasPermission = permissions?.deposits?.pageView
      if (!hasPermission) feedbackMessage(errorBackMessages['deposits.pageView'])
      break
    case '/dashboard/company/balance':
      hasPermission = permissions?.flashCash?.pageView
      if (!hasPermission) {
        feedbackMessage(errorBackMessages['flashCash.pageView'])
      }
      break
  }

  if (Number(HROS)) {
    Sentry.captureMessage(`embedded access for employeeNanoId ${getAccessTokenPayloadSync()?.employeeId}`)
  }

  return (
    <SentryRoute
      {...rest}
      render={props => (
        (isLoggedIn(timestamp, path, env) && hasPermission)
          ? <Component {...props} />
          :
          <Redirect
            to={{ pathname: '/' }}
          />
      )}
    />
  )
}

const OnlyPublicRoute = ({ component: Component, ...rest }) => {
  if (Number(MAINTENANCE_PAGE)) {
    return (
      <SentryRoute
        component={asyncComponent(() => import('./components/Organisms/MaintenancePage/maintenancePageLayer'))}
      />
    )
  }

  const { timestamp, type, path, env } = getFromLS('clientData') || {}
  return (
    <SentryRoute
      {...rest}
      render={props =>
        isLoggedIn(timestamp, path, env) ?
          <Redirect
            to={{ pathname: `/dashboard/${type}/${path}` }}
          /> :
          <Component {...props} />
      }
    />
  )
}

const isLoggedIn = (timestamp, path, env) => {
  const SESSION_IN_HOURS = Number(HROS)
    ? 168
    : 12

  if (
    !!path && !!timestamp && moment().isBefore(moment(timestamp).add(SESSION_IN_HOURS, 'hours'))
    && (URL === env || URL === 'featureBranch')
  ) {
    return true
  } else {
    setInLS('clientData', {})
    return false
  }
}

const Routes = ({ history }) => {
  return (
    <ConnectedRouter history={history}>
      <BrowserRouter basename={Number(HROS) ? '/benefits' : ''}>
        <Switch>
          <RestrictedRoute
            key='dash'
            path='/dashboard'
            component={App}
          />

          <OnlyPublicRoute
            key='init-company-signup'
            exact
            path={'/company/signup'}
            component={() => window.location.replace(
              SIGNUP_URL,
            )}
          />

          <OnlyPublicRoute
            key='login'
            exact
            path={'/login'}
            component={asyncComponent(() => import('./containers/LogInAndOut'))}
          />

          <OnlyPublicRoute
            key='redirect-fallback-route'
            path={'/*'}
            component={() => <Redirect to='/login' />}
          />

        </Switch>
      </BrowserRouter>
    </ConnectedRouter>
  )
}

export default connect()(Routes)
