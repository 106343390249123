import { all } from 'redux-saga/effects'
import apiManagement                  from '../containers/Company/ApiManagement/saga'
import app                            from '../containers/App/redux/saga'
import adminProfile                   from '../containers/AdminProfile/saga' 
import auxiliarPages                  from '../containers/AuxiliarPages/saga'
import companyCustomBenefits          from '../containers/Company/Benefits/saga'
import confirmAccount                 from '../containers/ConfirmAccount/saga'
import corporateProfile               from '../containers/CorporateProfile/saga'
import employees2Saga                 from '../containers/Company/Employees2/saga'
import getCompanyBillets              from '../containers/Company/Payments/saga'
import getCompanyDeposits             from '../containers/Company/Deposits/saga'
import getOrders                      from '../containers/Company/Cards/saga'
import logInAndOut                    from '../containers/LogInAndOut/saga'
import manageUsers                    from '../containers/ManageUsers/saga'
import reports                         from '../containers/Company/Reports/saga'
import benefitDiscovery                from '../containers/BenefitDiscovery/saga'


export default function* rootSaga() {
  yield all([
    apiManagement(),
    app(),
    adminProfile(),
    auxiliarPages(),
    companyCustomBenefits(),
    confirmAccount(),
    corporateProfile(),
    employees2Saga(),
    getCompanyBillets(),
    getCompanyDeposits(),
    getOrders(),
    logInAndOut(),
    manageUsers(),
    reports(),
    benefitDiscovery()
  ])
}
