import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { getS3SignedUrlReducer as signedUrlReducer }  from '../containers/App/redux/reducer'
import adminProfile               from '../containers/AdminProfile/reducer'
import auxiliarPages              from '../containers/AuxiliarPages/reducer'
import apiManagement              from '../containers/Company/ApiManagement/reducer'
import appReducer                 from '../containers/App/redux/reducer'
import companyCustomBenefits      from '../containers/Company/Benefits/reducer'
import companyOrders              from '../containers/Company/Cards/reducer'
import confirmAccount             from '../containers/ConfirmAccount/reducer'
import corporateProfile           from '../containers/CorporateProfile/reducer'
import employees2                 from '../containers/Company/Employees2/reducer'
import getCompanyBillets          from '../containers/Company/Payments/reducer'
import getCompanyDeposits         from '../containers/Company/Deposits/reducer'
import logInAndOut                from '../containers/LogInAndOut/reducer'
import manageUsers                from '../containers/ManageUsers/reducer'
import reports                    from '../containers/Company/Reports/reducer'
import benefitDiscovery            from '../containers/BenefitDiscovery/reducer'

export default history => combineReducers({
  router: connectRouter(history),

  adminProfile,
  apiManagement,
  appReducer,
  auxiliarPages,
  companyCustomBenefits,
  companyOrders,
  confirmAccount,
  corporateProfile,
  employees2,
  getCompanyBillets,
  getCompanyDeposits,
  logInAndOut,
  manageUsers,
  reports,
  signedUrlReducer,
  benefitDiscovery
})
